<template>
  <!-- Display if:
  * login_address === display_name
  * login_address === name
   -->
  <div class="banner" v-if="displayBanner">
    Your profile is not complete.
    <span
      style="text-decoration: underline; cursor: pointer;"
      @click.stop="$emit('openProfileSettings')"
    >
      Click here
    </span>
    to complete it.
  </div>
</template>
<script>
export default {
  name: 'TopBanner',
  mounted () {
    // console.log(this.$store.state.userData)
  },
  computed: {
    displayBanner () {
      const user = this.$store.state.userData
      return user.id !== undefined && (user.login_address === user.name || user.login_address === user.name)
    }
  }
}
</script>
<style scoped lang="stylus">
.banner {
  background-color: #ddd7e7;
  color: #5f5475;
  padding: 10px;
}
</style>
